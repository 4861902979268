<template>
  <div>
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      success-text="刷新成功"
    >
      <div class="top">
        <div class="setting">
          <div class="icon-xiazai" @click="$router.push('/downloadImg')"></div>
          <div class="icon-fenxiang" @click="shareIt"></div>
          <div class="icon" @click="setting"></div>
        </div>
        <div class="invitation_information" v-if="identity == 1">
          <div class="userinfo">
            <img
              src="https://yhaocang-img.oss-accelerate.aliyuncs.com/1/pc/1607917618148.png"
            />
            <span class="title">邀请码</span>
            <span
              class="invitation-code"
              v-clipboard:copy="totalAmount.invite_code"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >{{ totalAmount.invite_code }}</span
            >
          </div>
        </div>
        <div class="cash-card">
          <div class="withdraw" @click="$router.push('/withdrawal')">提现</div>
          <div class="balance-title">
            <div>查看余额</div>
            <!-- <u-icon
            size="34"
            name="eye"
            :custom-style="{ marginLeft: '10px' }"
          ></u-icon> -->
          </div>
          <div class="amount">￥{{ totalAmount.left_profit || 0 }}</div>
          <div class="income">
            <div>
              <div>累计收益</div>
              <div class="income-amount">
                ￥{{ totalAmount.total_profit || 0 }}
              </div>
            </div>
            <div>
              <div>今日收益</div>
              <div class="income-amount">
                ￥{{ totalAmount.todayProfit || 0 }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="my-card">
          <div class="title">
            <div>我的会员卡(张)</div>
            <div class="share" @click="$router.push('/myCard')">查看</div>
          </div>
          <div class="type-card">
            <div class="item">
              <div class="type">月卡</div>
              <div class="remaining">剩余</div>
              <div class="num">
                <div>{{ cardCount.month_no_exchange }}</div>
                <div>(张)</div>
              </div>
              <div class="use">已使用:{{ cardCount.month_exchange }}</div>
              <div class="use">总购卡:{{ cardCount.month }}</div>
            </div>
            <div class="item">
              <div class="type" style="background-color: #4b74f2">季卡</div>
              <div class="remaining">剩余</div>
              <div class="num">
                <div style="color: #4b74f2">
                  {{ cardCount.season_no_exchange }}
                </div>
                <div>(张)</div>
              </div>
              <div class="use">已使用:{{ cardCount.season_exchange }}</div>
              <div class="use">总购卡:{{ cardCount.season }}</div>
            </div>
            <div class="item">
              <div class="type" style="background-color: #ff3a51">年卡</div>
              <div class="remaining">剩余</div>
              <div class="num">
                <div style="color: #ff3a51">
                  {{ cardCount.year_no_exchange }}
                </div>
                <div>(张)</div>
              </div>
              <div class="use">已使用:{{ cardCount.year_exchange }}</div>
              <div class="use">总购卡:{{ cardCount.year }}</div>
            </div>
          </div>
        </div>

        <van-tabs
          v-model="active"
          title-active-color="#FBC034"
          title-inactive-color="#999"
          @click="tabClick"
        >
          <!-- 循环渲染list列表 -->
          <van-tab
            :title="item.name"
            v-for="(item, index) in list"
            :key="index"
          ></van-tab>
        </van-tabs>
        <!-- 渲染完成 -->
        <van-empty description="暂无记录" v-if="showempty" />
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          v-else
        >
          <!-- tab栏<1> -->
          <div class="list" v-if="active == 0">
            <div class="item" v-for="(item, index) in dataList" :key="index">
              <div class="line1">
                <div>{{ item.typeStr }}</div>
                <div class="price fl">￥{{ item.vip_card_price }}</div>
              </div>
              <div class="line2">
                <div>{{ item.add_time }}</div>
                <div>x{{ item.card_num }}</div>
              </div>
            </div>
          </div>
          <!-- tab栏<2> -->
          <div class="list" v-else-if="active == 1">
            <div
              class="item"
              v-for="(item, index) in exchangeList"
              :key="index"
            >
              <div class="line1">
                <div>{{ item.typeStr }}</div>
                <div class="price">{{ item.exchangeStr }}</div>
              </div>
              <div class="line2">
                <div>{{ item.add_time }}</div>
              </div>
            </div>
          </div>
          <!-- tab栏<3> -->
          <!-- 提现记录 -->
          <div class="list" v-else-if="active == 2">
            <div
              class="item"
              v-for="(item, index) in exchangeListNum"
              :key="index"
            >
              <div class="line1">
                <div class="title" v-if="item.payStr">
                  {{ item.payStr }}
                </div>
                <div class="title" v-if="item.bank_name">
                  {{ item.bank_name }}
                </div>
                <div class="amount">+{{ item.money }}</div>
              </div>
              <div class="line2">
                <div>
                  {{ item.status == 0 ? item.add_time : item.arrival_time }}
                </div>
                <div class="price">+{{ item.status_str }}</div>
              </div>
            </div>
          </div>
          <!-- 提现记录 -->
          <!-- tab栏<4> -->
          <!-- 收入明细 -->
          <div class="list" v-else>
            <div
              class="item"
              v-for="(item, index) in exchangeListNumList"
              :key="index"
            >
              <i class="icon"></i>
              <div class="line1">
                <div>
                  <div class="title">{{ item.title }}</div>
                </div>
                <div class="price">+{{ item.profit }}</div>
              </div>
              <div class="content2">
                <div class="line2">{{ item.add_time }}</div>
              </div>
            </div>
          </div>
          <!-- 收入明细 -->
        </van-list>
      </div>
    </van-pull-refresh>
    <van-action-sheet v-model="show" close-on-click-action cancel-text="取消">
      <div class="share-content">
        <p class="url">{{ shareUrl }}</p>
        <div
          class="copy-btn"
          v-clipboard:copy="shareUrl"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          @click="show = false"
        >
          复制链接
        </div>
      </div>
    </van-action-sheet>
    <van-overlay
      class="guide"
      :show="showTip"
      @click="show = false"
      z-index="1000"
    >
      <div class="content" @click.stop>
        <div class="box">
          <div class="img-tip">
            <img
              src="https://t.yhaocang.com/upload/sf_static/img/tabbar/data/xiazai-tip.png"
              alt=""
            />
            <img
              src="https://t.yhaocang.com/upload/sf_static/img/tabbar/data/fenxiang-tip.png"
              alt=""
            />
            <img
              src="https://t.yhaocang.com/upload/sf_static/img/tabbar/data/shezhi-tip.png"
              alt=""
            />
          </div>
          <div class="text-tip-content" v-if="stepCount == 1">
            <div class="vertical-line"></div>
            <div class="text-tip">线下推广海报下载都在这里。</div>
            <div class="next-step" @click="stepCount = 2">下一步</div>
          </div>
          <div class="text-tip-content rf110" v-if="stepCount == 2">
            <div class="vertical-line"></div>
            <div class="text-tip">线上链接推广都在这里</div>
            <div class="next-step" @click="stepCount = 3">下一步</div>
          </div>
          <div class="text-tip-content rf34" v-if="stepCount == 3">
            <div class="vertical-line"></div>
            <div class="text-tip">设置退出登录</div>
            <div class="next-step" @click="know">我知道了</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay
      class="share-it-tip"
      :show="shareTip"
      @click="shareTip = false"
      z-index="1000"
    >
      <div class="content">
        <div class="box">
          <img
            src="https://t.yhaocang.com/upload/sf_static/img/tabbar/data/youshangjiao.png"
            alt=""
          />
          <div class="text-tip">请点击右上角分享</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  getPurchaseRecord,
  getWxConfig,
  incomeSummary,
  membershipCardDetails,
  numberOfCards
} from "./../../api/data";
import { cashOut } from "@/api/recordDetails";
import { getIncome } from "@/api/recordDetails";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      show: false,
      shareTip: false,
      isLoading: false,
      loading: false,
      finished: false,
      showempty: false,
      exchangeListNum: [],
      exchangeListNumList: [],
      page: 1,
      shareUrl: `https://d.yhaocang.com/#/channel?rid=${localStorage.rid}`,
      totalAmount: {},
      cardCount: {},
      active: 0,
      index: 0,
      list: [
        {
          name: "购卡记录"
        },
        {
          name: "兑换明细"
        },
        {
          name: "提现记录",
          status: 1
        },
        {
          name: "收入明细"
        }
      ],
      dataList: [],
      exchangeList: [],
      identity: "",
      stepCount: 1,
      showTip: false
    };
  },
  created() {
    this.loading = true;
    this.identity = localStorage.identity;
    this.GetnumberOfCards();
    this.GetIncomeSummary();
    this.GetPurchaseRecord();
    if (localStorage.identity == 1) {
      return;
    }
    getWxConfig(window.location.href).then(res => {
      if (res.data.code == 200) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.data.data.appId, // 必填，公众号的唯一标识
          timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.data.signature, // 必填，签名
          jsApiList: [
            "updateAppMessageShareData",
            "updateTimelineShareData",
            "onMenuShareQZone"
          ] // 必填，需要使用的JS接口列表
        });
      }
    });
  },
  mounted() {
    wx.ready(() => {
      wx.updateAppMessageShareData({
        title: "【1号奢仓】- 奢侈品共享平台", // 分享标题
        desc: "爆款包包9.9起，会员免费送Gucci香水/迪奥999口红", // 分享描述
        link: this.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl:
          "https://t.yhaocang.com/upload/sf_static/img/tabbar/data/logo(4).png", // 分享图标
        success: () => {
          // this.$toast("分享成功");
        }
      });
      wx.updateTimelineShareData({
        title: "【1号奢仓】- 奢侈品共享平台", // 分享标题
        desc: "爆款包包9.9起，会员免费送Gucci香水/迪奥999口红", // 分享描述
        link: this.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl:
          "https://t.yhaocang.com/upload/sf_static/img/tabbar/data/logo(4).png", // 分享图标
        success: () => {
          // this.$toast("分享成功");
        }
      });
    });
    if (localStorage.read == undefined || localStorage.read == false) {
      this.showTip = true;
    }
  },
  methods: {
    know() {
      this.showTip = false;
      localStorage.read = true;
    },
    shareIt() {
      console.log(this.$store.state.isWeiXin);
      if (this.$store.state.isWeiXin) {
        this.shareTip = true;
      } else {
        this.show = true;
      }
    },
    onCopy() {
      this.$toast("内容已复制到剪切板！");
    },
    // 复制失败时的回调函数
    onError() {
      this.$toast("抱歉，复制失败！");
    },
    onLoad() {
      console.log("触发");
      switch (this.index) {
        case 0:
          this.GetPurchaseRecord();
          break;
        case 1:
          this.GetmembershipCardDetails();
          break;
        case 2:
          this.GetBehind();
          break;
        case 3:
          this.PetBehind();
          break;
        default:
      }
    },
    async onRefresh() {
      this.isLoading = false;
      this.finished = false;
      this.loading = true;
      this.dataList = [];
      this.exchangeList = [];
      this.exchangeListNum = [];
      this.exchangeListNumList = [];
      this.page = 1;
      this.GetnumberOfCards();
      this.GetIncomeSummary();
      switch (this.index) {
        case 0:
          await this.GetPurchaseRecord();
          break;
        case 1:
          await this.GetmembershipCardDetails();
          break;
        case 2:
          await this.GetBehind();
          break;
        case 3:
          await this.PetBehind();
          break;
        default:
          break;
      }
    },
    //tab 切换
    tabClick(e) {
      console.log(e);
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.dataList = [];
      this.exchangeList = [];
      this.exchangeListNum = [];
      this.exchangeListNumList = [];
      this.index = e;
      switch (e) {
        case 0:
          this.GetPurchaseRecord();
          break;
        case 1:
          this.GetmembershipCardDetails();
          break;
        case 2:
          this.GetBehind();
          break;
        case 3:
          this.PetBehind();
          break;
        default:
          break;
      }
    },
    setting() {
      console.log("跳转设置");
      this.$router.push("/signOut");
    },
    GetIncomeSummary() {
      incomeSummary().then(res => {
        console.log(res.data, "总结");
        this.totalAmount = res.data.data;
      });
    },
    //购卡记录
    GetPurchaseRecord() {
      getPurchaseRecord({
        page: this.page
      }).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          this.dataList.push(...res.data.data);
          ++this.page;
          this.loading = false;
          if (this.dataList.length <= 0 && res.data.data.length <= 0) {
            this.showempty = true;
          } else {
            this.showempty = false;
          }
          if (this.dataList.length >= 0 && res.data.data.length <= 0) {
            this.finished = true;
          }
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    //会员卡数量
    GetnumberOfCards() {
      numberOfCards().then(res => {
        console.log(res.data, "获取会员卡的数量");
        this.cardCount = res.data.data;
      });
    },
    //兑换明细
    GetmembershipCardDetails() {
      membershipCardDetails({
        page: this.page
      }).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          ++this.page;
          this.loading = false;
          // // console.log("w");
          console.log(...res.data.data.cardInfo);
          this.exchangeList.push(...res.data.data.cardInfo);
          if (
            this.exchangeList.length <= 0 &&
            res.data.data.cardInfo.length <= 0
          ) {
            this.showempty = true;
          } else {
            this.showempty = false;
          }
          if (
            this.exchangeList.length >= 0 &&
            res.data.data.cardInfo.length <= 0
          ) {
            this.finished = true;
          }
        } else {
          this.$toast(res.data.message);
        }
      });
      //  测试
    },
    // 提现记录
    GetBehind() {
      cashOut({ page: this.page, status: this.list[2].status }).then(res => {
        if (res.data.code == 200) {
          this.loading = false;
          this.exchangeListNum.push(...res.data.data);
          console.log(res.data.data.length);
          ++this.page;
          this.isLoading = false;
          if (this.exchangeListNum.length <= 0 && res.data.data.length <= 0) {
            this.showempty = true;
            console.log("niubi");
          } else {
            this.showempty = false;
          }
          if (this.exchangeListNum.length >= 0 && res.data.data.length <= 0) {
            this.finished = true;
          }
        }
      });
    },
    // 收入明细
    PetBehind() {
      getIncome({ page: this.page }).then(res => {
        if (res.data.code == 200) {
          this.exchangeListNumList.push(...res.data.data.list);
          console.log(res.data.data);
          ++this.page;
          this.isLoading = false;
          if (
            this.exchangeListNumList.length <= 0 &&
            res.data.data.list.length <= 0
          ) {
            this.loading = true;
            this.showempty = true;
          } else {
            this.loading = false;
            this.showempty = false;
          }
          if (
            this.exchangeListNumList.length >= 0 &&
            res.data.data.list.length <= 0
          ) {
            this.finished = true;
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.top {
  background: linear-gradient(0deg, #ffffff 0%, #fff2d4 100%);
  // opacity: 0.56;
  // height: 100%;
  // height: 721px;
  padding: 0 32px;
  padding-bottom: 50px;
  .setting {
    display: flex;
    justify-content: flex-end;

    padding-top: 75px;
    .icon {
      width: 57px;
      height: 57px;
      background: url("https://t.yhaocang.com/upload/sf_static/img/tabbar/data/setting.png")
        0 0 no-repeat;
      background-size: 100%;
      margin: 0 10px;
    }
    .icon-xiazai {
      width: 57px;
      height: 57px;
      background: url("https://t.yhaocang.com/upload/sf_static/img/tabbar/data/xiazai.png")
        0 0 no-repeat;
      background-size: 100%;
      margin: 0 10px;
    }
    .icon-fenxiang {
      width: 57px;
      height: 57px;
      background: url("https://t.yhaocang.com/upload/sf_static/img/tabbar/data/fenxiang.png")
        0 0 no-repeat;
      background-size: 100%;
      margin: 0 10px;
    }
  }
  .invitation_information {
    display: inline-block;
  }
  .userinfo {
    height: 59px;
    background: #ffffff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    font-size: 29px;
    font-weight: 400;
    img {
      width: 59px;
      height: 59px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .title {
      color: #333333;
    }
    .invitation-code {
      color: #999999;
      margin-right: 30px;
      padding-left: 10px;
      margin-left: 10px;
      border-left: 1px solid #d2d2d2;
    }
  }
  .cash-card {
    position: relative;
    background: url("https://t.yhaocang.com/upload/sf_static/img/tabbar/data/cardbgc1.png")
      0 0 no-repeat;
    background-size: 100%;
    height: 407px;
    width: 692px;
    margin: auto;
    margin-top: 27px;
    padding-top: 67px;
    padding-left: 40px;
    .withdraw {
      position: absolute;
      right: 26px;
      top: 63px;
      width: 134px;
      height: 59px;
      background: #ffffff;
      box-shadow: 0px 1px 11px 0px rgba(179, 135, 32, 0.46);
      border-radius: 30px;
      font-size: 31px;
      font-weight: 500;
      color: #fbb81a;
      text-align: center;
      line-height: 59px;
    }
    .balance-title {
      color: #fff;
      display: flex;
      font-size: 26px;
      align-items: center;
    }
    .amount {
      margin-left: auto;
      font-size: 70px;
      font-weight: 500;
      color: #fff;
    }
    .amount::first-letter {
      font-size: 36px;
    }
    .income {
      display: flex;
      margin-top: 60px;
      > div {
        width: 50%;
        color: #fff;
        > div::nth-child(1) {
          font-size: 26px;
        }
        .income-amount {
          font-size: 42px;
          margin-top: 15px;
        }
        .income-amount::first-letter {
          font-size: 36px;
        }
      }
    }
  }
}
.my-card {
  padding: 0 31px;
  .title {
    font-size: 31px;
    font-weight: 400;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .share {
      width: 117px;
      // height: 41px;
      background: #fddb83;
      opacity: 0.7;
      border-radius: 20px;
      font-size: 25px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      text-align: center;
      line-height: 41px;
    }
  }
  .type-card {
    display: flex;
    justify-content: space-between;
    .item {
      width: 208px;
      // height: 276px;
      background: #ffffff;
      border: 1px solid #ff7410;
      border-radius: 21px;
      padding-bottom: 25px;
      margin-top: 47px;
      .type {
        width: 81px;
        height: 40px;
        background: #ff7410;
        border-radius: 0px 20px 0px 20px;
        margin-left: auto;
        margin-top: -1px;
        margin-right: -1px;
        font-size: 25px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
      }
      .remaining {
        font-size: 25px;
        font-weight: 400;
        color: #333333;
        margin-top: 8px;
        padding: 0 15px;
      }
      .num {
        display: flex;
        align-items: baseline;
        padding: 0 15px;
        padding-bottom: 32px;
        font-size: 19px;
        div:nth-child(1) {
          font-size: 69px;
          font-weight: 900;
          color: #ff7410;
        }
      }
      .use {
        padding: 0 15px;
        font-size: 21px;
        font-weight: 600;
        color: #999999;
        line-height: 36px;
      }
    }
  }
}
.list {
  padding: 0 31px;
  min-height: 70vh;
  .item {
    line-height: 48px;
    margin-top: 40px;
    border-bottom: 1px solid #d2d2d2;
    .line1 {
      color: #333333;
      font-size: 28px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;

      .price {
        font-size: 27px;
        font-weight: 900;
        color: #333333;
      }
      .fl::first-letter {
        font-size: 22px;
      }
    }
    .line2 {
      display: flex;
      justify-content: space-between;
      font-size: 25px;
      font-weight: 400;
      color: #999999;
    }
  }
}
/deep/.van-tabs__line {
  background-color: #fbc034;
}
.wrapper {
  display: flex;
  // align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  // background-color: #fff;
  margin-top: 100px;
  img {
    width: 80%;
    display: block;
    margin: auto;
  }
  .content {
    width: 80%;
    background: #fff;
    margin: auto;
    line-height: 60px;
    text-align: center;
    color: #999999;
  }
}
.share-content {
  min-height: 300px;
  padding: 20px;
  .url {
    text-align: center;
    font-size: 35px;
    width: 100%;
    word-wrap: break-word;
  }
  .copy-btn {
    width: 150px;
    line-height: 60px;
    text-align: center;
    border-radius: 50px;
    border: 1px solid #333;
    margin: auto;
    margin-top: 30px;
  }
}
.guide {
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
  }
  .box {
    height: 100%;
    width: 100%;
    // background-color: #fff;
    .img-tip {
      display: flex;
      justify-content: flex-end;
      padding: 0 30px;
      margin-top: 60px;
      img {
        width: 60px;
        height: 60px;
        margin: 8px;
      }
    }
    .text-tip-content {
      display: inline-block;
      position: absolute;
      right: 186px;
      .vertical-line {
        width: 4px;
        height: 112px;
        background: #ffffff;
        margin-right: 30px;
        margin-left: auto;
      }
      .text-tip {
        display: inline-block;
        line-height: 69px;
        background: #ffffff;
        border-radius: 7px;
        font-size: 28px;
        font-weight: 400;
        color: #424243;
        padding: 0 20px;
      }
      .next-step {
        padding: 0 20px;
        width: 150px;
        line-height: 50px;
        // color: #fff;
        background: #fff;
        border-radius: 50px;
        text-align: center;
        margin: auto;
        margin-top: 30px;
      }
    }
    .rf110 {
      right: 110px;
    }
    .rf34 {
      right: 34px;
    }
  }
}
.share-it-tip {
  .content {
    height: 100%;
    width: 400px;
    margin-left: auto;
    .box {
      img {
        display: block;
        width: 400px;
      }
      .text-tip {
        display: inline-block;
        line-height: 69px;
        background: #ffffff;
        border-radius: 7px;
        font-size: 28px;
        font-weight: 400;
        color: #424243;
        padding: 0 20px;
      }
    }
  }
}
.content2 {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .amount {
    margin-left: auto;
    font-size: 35px;
    font-weight: 500;
    color: #333333;
  }
}
.line2 {
  color: #333333;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 29px;
  font-weight: 500;
  color: #333333;
}
/deep/.van-tabs--line .van-tabs__wrap {
  margin-top: 40px;
}
</style>
